#chartjs-tooltip {
	content: "";
	@apply absolute opacity-100 p-2 bg-black bg-opacity-80 transition-all ease-[ease] duration-200 -translate-x-1/2 text-white pointer-events-none rounded;

	.custom-doughnut-chart-tooltip-wrapper {
		.tooltip-title {
			@apply font-semibold text-sm;
		}
		.tooltip-body {
			@apply flex items-center mt-0.5;
			.tooltip-indicator {
				@apply w-2.5 h-10 rounded-sm mr-1;
			}
			.tooltip-prices {
				.tooltip-price-item {
					@apply text-[12px] leading-4 whitespace-nowrap;
					.price {
						@apply font-semibold;
						&.price-sum {
							@apply text-yellow-500;
						}
						&.price-currency {
							@apply text-green-500;
						}
						&.price-total {
							@apply text-base-1;
						}
					}
				}
			}
		}
	}
}
// #chartjs-tooltip.below:before {
// 	content: "";
// 	@apply w-0 h-0 border-8 border-b-0 border-solid border-black border-opacity-80 border-l-transparent border-r-transparent -bottom-4 left-1/2 block absolute z-[99] -translate-x-1/2 -translate-y-full;
// }
// #chartjs-tooltip.above:before {
// 	border: solid;
// 	border-color: #111 transparent;
// 	border-color: rgba(0, 0, 0, 0.8) transparent;
// 	border-width: 8px 8px 0 8px;
// 	bottom: 1em;
// 	content: "";
// 	display: block;
// 	left: 50%;
// 	top: 100%;
// 	position: absolute;
// 	z-index: 99;
// 	-webkit-transform: translate(-50%, 0);
// 	transform: translate(-50%, 0);
// }
